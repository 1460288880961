.min-height{
    min-height: calc(100vh - 100px);
}
.title_container {
    text-align: center;
    font-size: 28px;
    color: #f100ff;
    padding: 10px;
    margin-top: 20px;
}
a{
    color: rgb(6, 237, 253);
}
.tour_container{
   display: contents;
   margin-bottom: 30px;
}
.tourtable{
    background: #000;
}
.playertable{
    background: #1d1e23;;
}
.teamtable{
    background: #1d1e23;;
}