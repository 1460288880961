.home{
    min-height: calc(100vh - 56px);
    padding: 30px;
}

.banner_text{
    display: grid;
    place-items: center;
    padding: 50px 0;
    text-align: center;
}
.home_banner h3{
    text-shadow: 0 0 5px  #c41c91, 0 0 15px #c41c91, 0 0 20px #c41c91,
    0 0 40px #7802af, 0 0 60px #7802af, 0 0 10px #7802af, 0 0 98px #000;
    color: #ffffff;
    text-align: center;
}


.home_banner .banner_btn {
    justify-content: center;
    background-color: transparent;
    border: 2px solid #e74c3c;
    border-radius: 0.6em;
    color: #e74c3c;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    padding: 12px;
    text-decoration: none;
    text-align: center;
    font-family: "Poppins";
    text-transform: uppercase;
      }
.home_banner .banner_btn:hover, .btn:focus {
    color: #fff;
    background: #e74c3c;
    outline: 0;
}
.banner_btns{
    display: grid;
    gap: 20px;
}

@media only screen and (min-width: 37.5em) {

}

@media only screen and (min-width: 48em) {
    .banner_btns {
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    }
}

@media only screen and (min-width: 64em) {
    .home{
        min-height: calc(100vh - 56px);
        max-width: 1600px;
        margin: 30px auto;
        display: grid;
        place-items: center;
    }
   
    .home_banner h3{
        font-size: 40px;
    }
    .home_banner .banner_text .banner_btn{
        padding: 1.2em 2.8em;
    }
}

@media only screen and (min-width: 80em) {
    
}