.formheight{
    min-height: calc(100vh - 70px);
}
.user_auth {
    margin: 0 auto;
    padding: 160px 0;
    width: 100%;
    min-height: calc(100vh - 70px);
    background-size: cover;
}
.user_auth .title {
    margin-bottom: 25px;
    text-align: center;
    width: 100%;
    color: #fff;
}
.user_auth .title svg {
    fill: #fff;
    margin-right: 10px;
}
.user_auth .med {
    display: none;
}
.user_auth .auth_details {
    width: 254px;
    margin: 10px auto;
}
.user_auth .login_details,
.user_auth .otp-details {
    width: 250px;
}   
.user_auth .login_error {
    color: red;
    display: block;
    font-size: 12px;
    margin-top: 10px;
}
.user_auth.login .forgot {
    margin: 5px 0 0;
    text-align: right;
}
.user_auth .auth_details .userid{
    width: 250px;
}
.user_auth .hide {
    display: none;
}
.user_auth.login .login_details #login {
    margin-right: 20px;
}
.user_auth .field_wrapper {
    margin: 20px 0;
    position: relative;
}
.user_auth .field_wrapper label {
    font-size: 14px;
    height: 40px;
    left: 0;
    padding: 12px 14px;
    pointer-events: none;
    position: absolute;
    text-align: left;
    top: 0;
    transition: top 0.1s linear;
    width: 100%;
}
.user_auth .field_wrapper.focus label {
    color: #00bceb;
}
.user_auth .field_wrapper.filled label,
.user_auth .field_wrapper.focus label {
    background: #1d1e23;
    font-size: 10px;
    height: 16px;
    left: 0;
    padding: 3px 5px;
    top: -8px;
    width: auto;
    z-index: 1;
}
.user_auth .field_wrapper input {
    background: transparent;
    border: 2px solid #4d4c4c;
    border-radius: 4px;
    color: #dce0e6;
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 10px 12px;
    width: 250px;
}
.user_auth .field_wrapper.focus input {
    border: 2px solid #025879;
}
.user_auth .field_wrapper .warning {
    color: red;
    display: none;
    font-size: 10px;
    margin: 5px 0 0 5px;
}
.user_auth .field_wrapper.warn .warning {
    display: block;
}
.user_auth .field_wrapper .toggle_pwd {
    height: 24px;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 24px;
}
.user_auth .field_wrapper .toggle_pwd svg {
    display: none;
    cursor: pointer;
    fill: #4d4c4c;
}
.user_auth .field_wrapper .toggle_pwd svg.show,
.user_auth .field_wrapper.show-pwd .toggle_pwd svg.hide {
    display: block;
}
.user_auth .field_wrapper.show-pwd .toggle_pwd svg.show {
    display: none;
}
.user_auth .otp-details .back {
    cursor: pointer;
}

@media only screen and (min-width: 37.5em) {
    .user_auth {
        width: 100%;
    }
    .user_auth .med {
        display: inline-block;
        padding: 5% 10%;
        vertical-align: top;
        width: 49%;
    }
    .user_auth .med img{
        padding: 10px 30px;
        width: 80%;
    }
    .user_auth .auth_details {
        display: inline-block;
        width: 49%;
    }
}

@media only screen and (min-width: 48em) {

    .user_auth {
        box-shadow: 0px 3px 8px 1px #000;
        margin: 80px auto;
        max-width: 780px;
        padding: 30px;
        min-height: calc(100vh - 520px);
    }
    .user_auth .med {
        padding: 18px 30px;
        text-align: center;
        vertical-align: top;
        width: 50%;
    }
    .user_auth .med img{
        padding: 10px 30px;
        width: 80%;
    }
    .user_auth .med svg {
        width: 200px;
    }
    .user_auth .auth_details {
        display: inline-block;
        font-size: initial;
        width: 50%;
    }
}

@media only screen and (min-width: 64em) {
    .formheight{
        min-height: calc(100vh - 170px);
    }
    .user_auth{
        min-height: 450px;
    }
}