.main,
.main * {
    box-sizing: border-box;
}
.main {
    flex-direction: column;
    min-height: calc(100vh - 245px);
}
.full-width,
.blowout,
.mx-width {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.reb_wrapper .main {
    display: grid;
}
.reb_wrapper .main.open {
    grid-template-columns: 1fr auto;
}
.mt50 {
    margin-top: 50px;
}
.mb50 {
    margin-bottom: 50px;
}
.blowout.row {
    flex-direction: row;
    padding: 2px 15px;
}
.full-width {
    padding: 0 15px;
    max-width: 1600px;
    margin-bottom: 14px;
    margin-top: -12px;
}
.mx-width {
    max-width: 1400px;
    padding: 0 15px;
}
.full-width .grp-title,
.mx-width .grp-title {
    height: 100px;
    padding: 30px 0;
    color: #ccc;
}
.page-title {
    margin: 0 0 20px;
    height: 100px;
    padding: 30px 0;
}
.page-title.txt-center {
    text-align: center;
}
.cnt-wrap .text {
    margin: 0 0 30px;
}
svg.mail,
svg.phone,
svg.discuss {
    fill: rgba(0, 188, 235, 0.8);
    margin: 0 8px -2px 0;
}
svg.new-win {
    fill: #0175a2;
    margin: 0 0 -2px 8px;
}
.wa-chat {
    bottom: 50px;
    line-height: 0;
    position: fixed;
    right: 30px;
    z-index: 2;
}
.wa-chat a {
    background: #60d66a;
    border-radius: 50%;
    display: inline-block;
    line-height: 0;
}
.wa-chat a:hover {
    box-shadow: 0px 0px 4px 3px #666;
}
.wa-chat a svg {
    height: 50px;
    width: 50px;
}
.nav.arrow {
    height: 40px;
    padding: 8px 4px;
    width: 40px;
}
.nav.prev,
.nav.next {
    cursor: pointer;
}
.nav.arrow::before {
    border-bottom: 7px solid #4d4c4c;
    border-right: 7px solid #4d4c4c;
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    transform: rotate(-45deg);
}
.nav.arrow.prev::before {
    transform: rotate(135deg);
}
.home-bg {
    width: 34%;
    height: 67%;
    background: #282b34;
    transform: skewY(-197deg);
    position: absolute;
    top: 323px;
    right: 202px;
    border-radius: 5%;
    animation-name: kadulu;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    box-shadow: 3px 34px 1px 2px #222;
}
.msg.error,
.msg.warning {
    background-color: #000;
    border-radius: 5px;
    min-width: 350px;
    margin: 0 auto 10px;
    padding: 10px;
    display: inline-block;
}
.msg.error p,
.msg.warning p,
.msg.warning ul {
    color: #b0b0b0;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
}
.msg.error svg,
.msg.warning svg {
    margin: 0 20px 0 0;
    vertical-align: top;
    width: 24px;
}
.msg.warning ul li {
    display: inline-block;
}
.pre-loader {
    display: inline-block;
    margin-top: 30px;
    width: 80px;
    height: 80px;
}
.pre-loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #00bceb;
    border-color: #00bceb transparent #00bceb transparent;
    animation: preloader 1.2s linear infinite;
}
@keyframes preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 37.5em) {
    .blowout.row {
        padding: 6px 20px;
    }
    .full-width {
        padding: 0 20px;
    }
    .mx-width {
        padding: 5px 52px;
        display: grid;
    }
    .reb_wrapper{
    grid-template-columns: 0fr auto;
    }
}

@media only screen and (min-width: 64em) {
    .mx-width.bg {
        padding: 0 40px;
    }
    .reb_wrapper .main {
        display: grid;
        grid-template-columns: 50px auto;
    }
    .reb_wrapper .main.open {
        grid-template-columns: 250px auto;
    }
}