table.border {
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;
}
table {
    margin-bottom: 30px;
}
table.border th,
table.border td {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}
table th,
table td {
    font-size: 14px;
    padding: 5px;
    text-align: center;
}
table.home td {
    font-size: 10px;
}
table td select {
    width: 115px;
}
table td textarea.medium,
table td select.medium,
table td input.medium {
    width: 180px;
}
table th {
    color: #05d4eb;
}
table td {
    color: #fff;
}
table td textarea.large,
table td select.large,
table td input.large {
    width: 250px;
}
table td textarea.x-large,
table td select.x-large,
table td input.x-large {
    width: 100%;
}
table td input {
    width: 140px;
}
table td input.small {
    width: 75px;
}
table td input.medium {
    width: 100px;
}
table td input.noarrows {
    -moz-appearance: textfield;
}
table td .prod-span {
    cursor: pointer;
    margin: 5px;
}